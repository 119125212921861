import axios from "axios";

export const api_base = 'http://fanmanager.sbs:3001'

//usr--------------------------------------------

export function login(username, password) {
  return axios.post(`${api_base}/usr/login`, { username, password })
}

export function signup(username, password, email) {
  return axios.post(`${api_base}/usr/signup`, { username, password, email })
}

export function getprofile(token) {
  return axios.get(`${api_base}/usr/info?token=${token}`)
}

export function delusr(uid) {
  return axios.delete(`${api_base}/usr/delete`, { params: { uid: uid } });
}

export function delusrs(uids) {
  return axios.delete(`${api_base}/usr/deletes`, { data: { uids } });
}

export function getusers(token) {
  return axios.get(`${api_base}/usr/all`, { params: { token } });
}

//fan--------------------------------------------
export function getFans() {
  return axios.get(`${api_base}/fan/all`)
}

export function getupdating() {
  return axios.get(`${api_base}/fan/updating`)
}

export function addfan(data) {
  return axios.post(`${api_base}/fan/add`, data)
}

export function updatefan(data) {
  return axios.post(`${api_base}/fan/update`, data)
}

export function delfan(fid) {
  const params = { fid: fid };
  return axios.delete(`${api_base}/fan/delete`, { params });
}

export function getfanone(fid) {
  return axios.get(`${api_base}/fan/getone`, { params: { fid: fid } });
}

//episode--------------------------------------------
export function delepisode(episodeId) {
  return axios.delete(`${api_base}/episode/delete`, { params: { eid: episodeId } });
}

export function addepisode(data) {
  return axios.post(`${api_base}/episode/add`, data);
}

export function getepisode(eid,token) {
  return axios.get(`${api_base}/episode/getOne`, { params: { eid: eid,token:token } });
}

//comment--------------------------------------------
export function addreview(father, eid, content, token) {
  return axios.post(`${api_base}/review/add`, { father: father, eid: eid, content: content }, { params: { token: token } });
}

export function delreview(rid, token) {
  return axios.delete(`${api_base}/review/delete`, { params: { rid: rid, token: token } });
}

export function getallreview(token) {
  return axios.get(`${api_base}/review/all`, { params: { token: token } });
}

export function approve(rid, token) {
  return axios.post(`${api_base}/review/approve`, {},{ params: {  rid: rid ,token: token} });
}

export function reject(rid, token,reason) {
  return axios.post(`${api_base}/review/reject`, {},{ params: { token: token, rid: rid ,reason:reason} });
}

export function likereview(rid, token) {
  return axios.post(`${api_base}/review/like`, {}, { params: { rid: rid, token: token } });
}

//favorite--------------------------------------------
export function addfavorite(eid, token) {
  return axios.post(`${api_base}/favourite/add`, {eid:eid},{ params: { token: token } } );
}

export function delfavorite(favid, token) {
  return axios.delete(`${api_base}/favourite/delete`, { params: { favid: favid, token: token } });
}

export function getfavoriteall(token)
{
  return axios.get(`${api_base}/favourite/getall`, { params: { token: token } });
}

//history--------------------------------------------

export function gethistory(token) {
  return axios.get(`${api_base}/history/all`, { params: { token: token } });
}

