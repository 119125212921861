import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import {numtotype} from './TypeandNum';
import { getfanone } from './Server';
import { api_base } from './Server';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';




function BasicInfo({tvShowData,loading}) {

  function getWeekday(day)
  {
    switch(day)
    {
      case '1':return "周一";
      case '2':return "周二";
      case '3':return "周三";
      case '4':return "周四";
      case '5':return "周五";
      case '6':return "周六";
      case '7':return "周日";
      default:return "未知";
    }
  }



  return (
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={10}>
      {loading ? <Skeleton variant="rectangular" animation="wave" width={300} height={400} /> :
        <motion.img
          src={tvShowData.coverImage}
          alt="封面图片"
          style={styles.coverImage}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
        />
      }
      <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
      <motion.h1 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.1 }}>
        {loading ? <Box sx={{ width: 300 }}><Skeleton animation="wave" /></Box> : tvShowData.title}
      </motion.h1>
      
      <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
        类型：
        {tvShowData.genre.map((genre, index) => (
          <span key={index} style={styles.genreTag}>{numtotype(genre.tid)}</span>
        ))}
      </motion.p>
      <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
        更新时间：{getWeekday(tvShowData.updateDate)}
      </motion.p>
      <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
        状态：{tvShowData.state}
      </motion.p>
      </Stack>
      </Stack>

  );
}


// 电视剧组件
const FansShower = () => {
  const [tvShowData,settvShowData] = React.useState({
    title: '加载中....',
    coverImage: '/test.png',
    genre: [],
    updateDate: '加载中....',
    state:"加载中....",
    episodes: [
   
    
    ],
  });

  


  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const { fid } = useParams();

  React.useEffect(() => {
   console.log(fid);
   setLoading(true);
   getfanone(fid).then((res)=>{
      settvShowData({
        title: res.data.data.fan.fname,
        coverImage: api_base+"/cover/download?name="+res.data.data.fan.picpath,
        genre: res.data.data.types,
        updateDate:res.data.data.fan.weekday,
        state:res.data.data.fan.state,
        episodes: 
          res.data.data.episodes.map((episode, index) => {return { title: episode.ename, link: "/epic/"+episode.eid }})
        ,
      });
      setLoading(false);
      console.log(res);
    })
  }, []);



  return (
    <motion.div style={styles.container} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <BasicInfo tvShowData={tvShowData} loading={loading} />
      <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
        剧集列表
      </motion.h2>
      <motion.div style={styles.episodeList} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
        {tvShowData.episodes.map((episode, index) => (
          <a key={index} href={episode.link} rel="noopener noreferrer">
            <motion.button
              style={hoveredIndex === index ? { ...styles.button, ...styles.buttonHover } : styles.button}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {episode.title}
            </motion.button>
          </a>
        ))}
      </motion.div>
    </motion.div>
  );
};

// 样式对象
const styles = {
  container: {
    width: '80%',
    margin: '0 auto',
    padding: '20px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
  },
  coverImage: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '10px',
    marginBottom: '20px',
    transition: 'transform 0.3s ease',
    width: 300,
     height: 400
  },
  genreTag: {
    display: 'inline-block',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    padding: '2px 8px',
    marginLeft: '5px',
    fontSize: '14px',
  },
  episodeList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'left',
    gap: '10px',
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
   
    height: '50px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  buttonHover: {
    backgroundColor: '#45a049',
  },
};

export default FansShower;
