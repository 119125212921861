import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { addepisode } from './Server';
import CircularProgress from '@mui/material/CircularProgress';
import SuccessTip from './SuccessTip';
import UploadButton from './UploadvideoButton';

export default function Episodeinfo({mydata,setMydata,open,setOpen,myfid,setFresh}) {
 
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [url,seturl]=React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setMydata({ename:"",orderIndex:"",url:""});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Addepisodes = async () => {
    setLoading(true);
    setProgress(0);
    let j=0;
    for(let i=1;i<=12;i++)
    {
      addepisode({ename:"第"+i+"集",orderIndex:i,url:"",fid:myfid})
      .then((res)=>{
        j++;
        console.log(res);
        setProgress(pre=>pre+100/12);
        if(j==12)
        {
          setLoading(false);
          setSuccess(true);
        }
      });
    }
      
    
  }


  return (
    <React.Fragment>
         <IconButton onClick={handleClickOpen}>
                    <AddCircleIcon />
                </IconButton>
      
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            setSuccess(false);
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const ename = formJson.name;
            const orderIndex = formJson.orderIndex;
            const url = formJson.url;
            const fid =myfid;
            console.log({ename,orderIndex,url,fid});
            addepisode({ename,orderIndex,url,fid}).then((res)=>{console.log(res); setSuccess(true);setFresh(pre=>1-pre);setOpen(false);});          
            //handleClose();
          },
        }}
      >
        <SuccessTip open={success} setOpen={setSuccess} />
        <DialogTitle>添加集</DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="name"
            fullWidth
            variant="standard"
          />
          
          <TextField
            autoFocus
            required
            margin="dense"
            type='number'
            id="name3"
            name="orderIndex"
            label="orderIndex"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="name2"
            value={mydata.url}
            name="url"
            label="url"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <UploadButton type="button" seturl={setMydata}/>
        <DialogActions>
          <Button onClick={handleClose}>关闭</Button>
          <Button type="submit">添加并关闭</Button>
          
          {loading && <CircularProgress variant="determinate" value={progress} />}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}