import React, { useState, useEffect } from 'react';
import { getallreview,approve,reject } from './Server';
import Commentadmin from './Commentadmin';
import Button from '@mui/material/Button';

export default function Examine({logindata}) {

    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [reviews, setReviews] = React.useState([]);
    const [visit, setvisit] = React.useState(Array(reviews.length).fill(false));
    const [myfresh, setMyfresh] = React.useState(1);

    React.useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await getallreview(localStorage.getItem('token'));
                
                setReviews(response.data.data.reviews);
                setvisit(Array(response.data.data.reviews.length).fill(false));
                console.log(reviews);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
            setLoading(false);
        };

        fetchData();
    }, [myfresh]);

    return (
        <div>
            <h1>审核中心</h1>
            <Button variant="outlined" onClick={()=>setMyfresh((pre)=>1-pre)}>
                刷新
            </Button>
            {loading ? <p>loading...</p> : null}
            <Commentadmin reviews={reviews} logindata={logindata} visit={visit} setvisit={setvisit}/>

        </div>
    );
}
