import React, { useState } from 'react';
import axios from 'axios';
import { api_base } from './Server';

const UploadButton = ({seturl}) => {
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);


  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!videoFile) {
      alert("Please select a video file first.");
      return;
    }

    const formData = new FormData();
    formData.append('file', videoFile);

    try {
      const response = await axios.post(api_base+'/video/upload', formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });
      alert('Upload successful!');
      console.log('Server response:', response.data);
      seturl((pre)=>({...pre,url:response.data.data.filename}));
    } catch (error) {
      console.error('Error uploading video:', error);
      alert('Failed to upload video. Please try again.');
    }
  };

  return (
    <div>
      <h4>上传视频</h4>
      <input type="file" accept="video/*" onChange={handleFileChange} />
      <button type="button" onClick={handleUpload}>Upload</button>
      {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
    </div>
  );
};

export default UploadButton;
