import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import EnhancedTable from './EnhancedTable';
import EpisodeManager from './EpisodeManager';
import Examine from './Examine';


export default function Admin({logindata}) {
  const [value, setValue] = React.useState('basic');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const styles = {
    scheduleContainer: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '20px auto',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }
    };

    return (
        <div style={styles.scheduleContainer}>
            <Box sx={styles.tabContainer}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value="basic" label="基本信息" />
                    <Tab value="video" label="审核中心" />
                    
                    
                </Tabs>
                <Box sx={{ width: '100%' }}>
                    {value === 'basic' && <EnhancedTable />}
                    {value === 'video' && <Examine logindata={logindata}/>}
                </Box>
            </Box>
        </div>
    );
}
