import Button from '@mui/material/Button';
import * as React from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { delreview } from './Server';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function DelCommentButton({ rid, setfresh }) {

    const [loading, setLoading] = React.useState(false);
    const [erropen, seterropen] = React.useState(false);
    const [successopen, setsuccessopen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        seterropen(false);
      };

      const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setsuccessopen(false);
      };

    function handleClick() {
        setLoading(true);
        
        delreview(rid, localStorage.getItem('token')).then((res) => {

            setLoading(false);
            if(res.data.code==20003)
            {
                seterropen(true);
            }
            else if(res.data.code==20000)
            {
                setsuccessopen(true);
            }
            setfresh(pre => 1 - pre);
        }
        )
    }

    return (
        <div>
            {loading ? <CircularProgress size={20} /> : <IconButton

                onClick={handleClick}
            >
                <DeleteIcon color='error' />
            </IconButton>}
            <Snackbar open={erropen} autoHideDuration={1500}  onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
                <Alert
                  onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    无权限删除评论
                </Alert>
            </Snackbar>
           
        </div>
    );
}