import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Addfaninfo from './Addfaninfo';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { updatefan, getfanone } from './Server';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { delepisode } from './Server';
import copy from 'copy-to-clipboard';

import Episodeinfo from './Episodeinfo';

const styles = {
    scheduleContainer: {
        fontFamily: 'Arial, sans-serif',
        maxWidth: '1200px',
        margin: '20px auto',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '900px', // 设置宽度为 80%
        maxWidth: '900px', // 设置最大宽度
        height: '700px', // 设置高度为 80%
        maxHeight: '700px', // 设置最大高度
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



export default function EpisodeManager({ open, setOpen, editformData }) {

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [fresh, setFresh] = React.useState(0);
    const [opens, setOpens] = React.useState(false);
    const [mydata, setMydata] = React.useState({ename:"",orderIndex:"",url:""});
    const handleClose = () => {
        setOpen(false);
    };

    const handleFresh = () => {
        setFresh(1 - fresh);
    }

    function handleClick() {
        console.info('You clicked the Chip.');
    }

    function handleDelete(eid) {
        console.info('You clicked the delete icon.');
        setLoading(true);
        delepisode(eid).then((response) => {
            setLoading(false);
            setEpisodes(episodes.filter((episode) => episode.eid !== eid));
        }).catch(error => {
            setLoading(false);
            console.error('Error:', error);
        }
        );
    }

    const [episodes, setEpisodes] = React.useState([

    ]);

    function addEpisode(ename, fid, url) {
        setEpisodes(pre => [...pre, { ename, fid, url }])
    }

    React.useEffect(() => {
        setEpisodes([]);
        if (open) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const response = await getfanone(editformData.fan.fid);
                    setEpisodes(response.data.data.episodes);
                    setLoading(false);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [open, fresh]);

    return (
        <React.Fragment>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {editformData.fan.fname}

                </DialogTitle>

                <IconButton
                    aria-label="fresh"
                    onClick={handleFresh}
                    sx={{
                        position: 'absolute',
                        right: 40,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    {loading ? <CircularProgress size={25} /> : <ReplayRoundedIcon />}
                </IconButton>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />

                </IconButton>
                <DialogContent dividers>
                    {episodes.map((episode, index) => (
                        <Chip
                            label={episode.ename + '，排序:' + episode.orderIndex}
                            color='info'
                            key={index}
                            onClick={()=>{
                                setMydata({ename:episode.ename,orderIndex:episode.orderIndex,url:episode.url});
                                setOpens(true);
                            }}
                            onDelete={() => handleDelete(episode.eid)}
                            style={{ margin: '5px' }}
                        />
                    ))}
                    <Episodeinfo mydata={mydata} setMydata={setMydata} open={opens} setOpen={setOpens} setFresh={setFresh} key={episodes.length} myfid={editformData.fan.fid} />



                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>
                        完成
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}