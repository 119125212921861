import React, { useState, useEffect } from 'react';
import { getfavoriteall, delfavorite } from './Server';
import Stack from '@mui/material/Stack';
import { LazyLoadImage } from "react-lazy-load-image-component";

const StarShower = () => {
  const [favorite, setFavorite] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getfavoriteall(localStorage.getItem('token'));
        console.log(res.data.data);
        setFavorite(res.data.data.favourites.favourites);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
      <h1 style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>收藏</h1>
      {favorite.map((item, index) => (
        <div key={index} style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #ddd',
          borderRadius: '5px',
          backgroundColor: '#fff'
        }}>
          <span style={{ fontSize: '16px', color: '#555' }}>name: {item.ename}</span>
          <div>
            <button onClick={() => {
              window.location.href = `/epic/${item.favourite.eid}`;
            }} style={{
              padding: '8px 12px',
              marginRight: '10px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}>查看</button>
            <button onClick={() => {
              delfavorite(item.favourite.favid, localStorage.getItem('token')).then((res) => {
                setFavorite(favorite.filter((_, i) => i !== index));
              });
            }} style={{
              padding: '8px 12px',
              backgroundColor: '#DC3545',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}>删除</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StarShower;
