import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Addfaninfo from './Addfaninfo';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { addfan } from './Server';

const styles = {
  scheduleContainer: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '1200px',
    margin: '20px auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '900px', // 设置宽度为 80%
        maxWidth: '900px', // 设置最大宽度
        height: '700px', // 设置高度为 80%
        maxHeight: '700px', // 设置最大高度
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Addfan({open,setOpen}) {

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [formData, setFormData] = React.useState({
    fan: {
      fname: '',
      weekday: '',
      region: '',
      year: '',
      state:'',
      picpath:'',
    },
    types: [],
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick=()=>{
    console.log('Form Data:', formData);
    setLoading(true);
    setSuccess(false);
  }

  React.useEffect(() => {
    if(loading)
    {
      addfan(formData).then((res)=>{
        console.log(res);
        setLoading(false);
        setSuccess(true);
      });
    }
  },[loading]);


  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {success?"添加成功":loading?"添加中":"添加番剧"}
        </DialogTitle>
        <Box sx={{ width: '100%' }}>
              {loading?<LinearProgress />:success? <LinearProgress color="success" variant="determinate" value={100}/>:<LinearProgress variant="determinate" value={0} />}
             </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          
            <Addfaninfo formData={formData} setFormData={setFormData}/>
         
        </DialogContent>
        <DialogActions>
        
          <Button autoFocus onClick={handleClick}>
            添加
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}