import React, { useState, useEffect } from 'react';
import { gethistory, getFans, api_base } from './Server';
import { motion } from 'framer-motion';
import Stack from '@mui/material/Stack';
import { LazyLoadImage } from "react-lazy-load-image-component";

const HistoryShower = () => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coverMap, setCoverMap] = useState(new Map());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const historyRes = await gethistory(localStorage.getItem('token'));
                if (historyRes.data.code !== 20000) {
                    alert(historyRes.data.message);
                    return;
                }
                setHistory(historyRes.data.data.Historys.filter(item => item.ename!=="失效视频"));
                console.log(historyRes.data.data.Historys);

                const fansRes = await getFans();
                const temp = new Map();
                fansRes.data.data.forEach(item => {
                    temp.set(item.fan.fid, { pic: item.fan.picpath, name: item.fan.fname });
                });
                setCoverMap(temp);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error (e.g., show error message)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
            <h1>历史记录</h1>
            {loading ? (
                <p>加载中...</p>
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{ marginTop: '20px' }}
                >
                    {history.map(item => (
                        <a key={item.fid} href={"/epic/" + item.history.eid} style={{ textDecoration: 'none' }}>
                            <motion.div

                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.3 }}
                                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                                style={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '10px', backgroundColor: '#fff', border: '1px solid #eee', borderRadius: '8px' }}
                            >
                                <Stack width="100%" direction="row" spacing={2} justifyContent="space-between" alignItems={'center'}>
                                    <div style={{ display: 'flex'}}>
                                        <div style={{ maxWidth: '100px', overflow: 'hidden', borderRadius: '8px', marginRight: '10px' }}>
                                            <LazyLoadImage src={api_base + "/cover/download?name=" + coverMap.get(item.fid).pic} alt="Fan Pic" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </div>
                                        <Stack direction="column" spacing={2} justifyContent={'center'} alignItems={'flex-start'}>
                                            <span style={{ color:'#000', fontSize: 20, fontWeight: 'bold', marginRight: '10px' }}>{coverMap.get(item.fid).name}</span>
                                            <span style={{ color:'#000',fontWeight: 'normal', marginRight: '10px' }}>{item.ename}</span>
                                        </Stack>
                                    </div>
                                    <span style={{ color: '#666' }}>{item.history.htime}</span>
                                </Stack>

                            </motion.div>
                        </a>
                    ))}
                </motion.div>
            )}
        </div>
    );
};

export default HistoryShower;
