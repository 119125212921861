import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { delfan, delusr, getFans } from './Server';


export default function DelButton({selected,selectitem,setselected,mydata,setmydata}) {

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [mycount, setMycount] = React.useState(0);
    const timer = React.useRef();
    React.useEffect(() => {
      return () => {
        clearTimeout(timer.current);
      };
    }, []);
  
    const buttonSx = {
      ...(success && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    function twoO(){
      return new Promise(function(resolve, reject) {
          console.log(222);
            resolve();
        });
    }
    
    function oneO(){
      return new Promise(function(resolve, reject) {
            console.log(111);
            resolve();
        });
    }
    var p = new Promise(function(resolve, reject) {
      resolve();
    });
  

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      clearTimeout(timer.current);
      setProgress(100.0 / (selected.length + 1));
      setMycount(0);
      let i=0;
      selected.map((item, index) => {
        if (selectitem == "Fans") {
          delfan(item).then((response) => {
            setMycount(precount => precount + 1);
            i++;
            setProgress(preprogress => preprogress + 100.0 / (selected.length + 1));
           
            if (i == selected.length ) {
              setSuccess(true);
              setLoading(false);
        
              timer.current = setTimeout(() => {
                console.log("setselected");
                setselected([]);
                setmydata(1 - mydata);
              }, 1000);
            }
          }).catch(error => {
            console.error('Error:', error);
            setProgress(preprogress => preprogress + 100.0 / (1 + selected.length));
          });
        }
        else if (selectitem == "Users")
          delusr(item).then((response) => {
            i++;
            setProgress(preprogress => preprogress + 100.0 / (selected.length + 1));
            if (i == selected.length ) {
              setSuccess(true);
              setLoading(false);
        
              timer.current = setTimeout(() => {
                console.log("setselected");
                setselected([]);
                setmydata(1 - mydata);
              }, 1000);
            }
          }).catch(error => {
            console.error('Error:', error);
            setProgress(preprogress => preprogress + 100.0 / (1 + selected.length));
          });
      })

     

    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative', width: 40, height: 40 }}> {/* 调整Box的大小 */}
        <Fab
          aria-label="save"
          color="primary"
          sx={{ ...buttonSx, width: 40, height: 40 }} 
          onClick={handleButtonClick}
        >
          {success ? <CheckIcon fontSize='small'/> : <DeleteIcon fontSize='small' />}
        </Fab>
        {
          <CircularProgress
            size={48} 
            value={progress}
            variant="determinate"
            sx={{
              color: green[500],
              position: 'absolute',
              top: -4,
              left: -4,
              zIndex: 1,
            }}
          />
        }
      </Box>
    </Box>
  );
}
