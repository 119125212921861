import React from 'react';
import axios from 'axios';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { motion } from 'framer-motion';
import { getupdating } from './Server';
import FileUpload from './FileUpload';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-tabs/style/react-tabs.css';

const Week = () => {

  const [schedule, setschedule] = React.useState({Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: []});
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(new Date().getDay()-1);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getupdating();
        console.log(response.data.data);
        setschedule({
          Monday: response.data.data.Monday,
          Tuesday: response.data.data.Tuesday,
          Wednesday: response.data.data.Wednesday,
          Thursday: response.data.data.Thursday,
          Friday: response.data.data.Friday,
          Saturday: response.data.data.Saturday,
          Sunday: response.data.data.Sunday
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    );
  }

  const styles = {
    scheduleContainer: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '20px auto',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    tabList: {
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: '#e0f7fa',
      padding: '10px',
      
      borderBottom: '1px solid #ddd',
    },
    tab: {
      padding: '10px 20px',
      cursor: 'pointer',
      color: '#006064',
      transition: 'all 0.3s ease',
     
    },
    tabSelected: {
      backgroundColor: '#ff8a80',
      color: '#fff',
      borderRadius: '4px',
      position: 'relative',
      
    },
    tabPanel: {
      padding: '20px',
      backgroundColor: '#ffffff',
      
    },
    card: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      marginBottom: '10px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      backgroundColor: '#fafafa',
    },
    cardHover: {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'scale(1.02)',
    },
    showName: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    episodes: {
      fontSize: '14px',
      color: '#757575',
    },
    link: {
      color: '#007bff',
      textDecoration: 'none',
      width: '100%', // Make the link take up the full width
    },
  };

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)} style={styles.scheduleContainer}>
      <TabList style={styles.tabList}>
        {Object.keys(schedule).map((day, index) => (
          <Tab key={day} style={selectedIndex === index ? { ...styles.tab, ...styles.tabSelected } : styles.tab}>
            {day}
          </Tab>
        ))}
      </TabList>
      
      {Object.entries(schedule).map(([day, shows], index) => (
        <TabPanel key={day} style={styles.tabPanel}>
          {shows.map((show, index) => (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0, transition: { delay: index * 0.05 } }}
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              key={index}
            >
              <a
                href={"/fans/" + show.fan.fid}
                key={show.name}
                style={styles.link}
              >
                <div style={styles.card}>
                  <span style={styles.showName}>{show.fan.fname}</span>
                  <span style={styles.episodes}>{show.episode.ename}</span>
                </div>
              </a>
            </motion.div>
          ))}
        </TabPanel>
      ))}
      {loading ? <CircularProgress size={40} /> : <></>}
    </Tabs>
  );
};

export default Week;
