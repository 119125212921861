import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import Charge from './Charge';
import CommentIcon from '@mui/icons-material/Comment';
export default function MessageShower({ logindata }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [opens, setOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <React.Fragment>
      <Charge open={opens} setOpen={setOpen} />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >

          <Avatar sx={{ width: 32, height: 32 }}>{logindata.islogin ? logindata.username[0] : ""}</Avatar>
          <Typography sx={{ display: { xs: 'none', sm: 'block' }, ml: 2, color: '#ffffcc' }} variant="h6" noWrap>
            {logindata.islogin ? logindata.username : "未登录"}
          </Typography>
        </IconButton>

      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar style={{ marginRight: 10 }}>{logindata.islogin ? logindata.username[0] : ""}</Avatar> {logindata.islogin ? logindata.username : "未登录"}
        </MenuItem>

        <Divider />

        {logindata.islogin ? <MenuItem onClick={() => { setOpen(true) }}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          升级高级会员
        </MenuItem> : <div></div>}


        {logindata.islogin ? <div><MenuItem onClick={() => {
          window.location.href = '/mycomment';
        }}>
          <ListItemIcon>
            <CommentIcon fontSize="small" />
          </ListItemIcon>
          我的评论
        </MenuItem>
        <MenuItem onClick={() => {
          if (localStorage.getItem('token') != null) {
            localStorage.removeItem('token');
            window.location.reload();
          }
        }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem></div> : <MenuItem onClick={() => {
          window.location.href = '/login';
        }}>
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          Login
        </MenuItem>}

      </Menu>
    </React.Fragment>
  );
}