// MessageList.js
import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { motion } from 'framer-motion';
import TabofMessage from './TabofMessage';
import PeopleIcon from '@mui/icons-material/People';


const MessageListWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #e5ffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const MessageItem = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;


const MessageContent = styled.div`
  flex: 1;
`;

const Name = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
`;

const Preview = styled.div`
  color: #555;
  margin-top: 5px;
`;

const Time = styled.div`
  color: #999;
  font-size: 0.9em;
  margin-left: 10px;
`;



const MessageList = () => {
  const [value, setValue] = React.useState(0);
  
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const messages = [
    {
      avatar: 'https://via.placeholder.com/50',
      name: 'Alice',
      preview: 'Hey, how are you?',
      time: '10:30 AM',
    },
    {
      avatar: 'https://via.placeholder.com/50',
      name: 'Bob',
      preview: 'Are we still on for today?',
      time: '09:15 AM',
    },
    {
      avatar: 'https://via.placeholder.com/50',
      name: 'Charlie',
      preview: 'Got the report. Thanks!',
      time: 'Yesterday',
    },
  ];

  const messages2 = [
    {
      avatar: 'https://via.placeholder.com/50',
      name: 'Admin',
      preview: 'Hey, how are you?',
      time: '10:30 AM',
    }
  ];
  
  return (
    <MessageListWrapper>
      <TabofMessage value={value} handleChange={handleChange}/>
      <div key={value}>
        
      {value==0?messages.map((message, index) => (
        <MessageItem
          key={index}
          initial={{ opacity: 0, y: 50  }}
          animate={{ opacity: 1, y: 0 ,transition: {delay: index * 0.05 } }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
         <Avatar sx={{ width: 50, height: 50 }} style={{"marginRight":"15px"}}>{message.name[0]}</Avatar>
          <MessageContent>
            <Name>{message.name}</Name>
            <Preview>{message.preview}</Preview>
          </MessageContent>
          <Time>{message.time}</Time>
        </MessageItem>
      )):messages2.map((message, index) => (
        <MessageItem
          key={index}
          initial={{ opacity: 0, y: 50  }}
          animate={{ opacity: 1, y: 0 ,transition: {delay: index * 0.05 } }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
         <Avatar sx={{ width: 50, height: 50 }} style={{"marginRight":"15px"}} >{message.name[0]}</Avatar>
          <MessageContent>
            <Name>{message.name}</Name>
            <Preview>{message.preview}</Preview>
          </MessageContent>
          <Time>{message.time}</Time>
        </MessageItem>
      ))}
      </div>
    </MessageListWrapper>
  );
};

export default MessageList;
