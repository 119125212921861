// Home.js
import * as React from 'react';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import Pagination from '@mui/material/Pagination';
import { motion } from 'framer-motion';
import './Home.css';
import SearchBox from './SearchBox';
import { getFans } from './Server';
import { numtotype, typenum } from './TypeandNum';
import FileUpload from './FileUpload';
import OnlineImage from './OnlineImage';
import { api_base } from './Server';
import { LazyLoadImage } from "react-lazy-load-image-component";
import CircularProgress from '@mui/material/CircularProgress';


const styles = {
  scheduleContainer: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '1200px',
    margin: '20px auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }
};

function ScrollableTabsButtonVisible({ value, setValue, mydata, setmydata, setKey }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) setmydata((mydata) => mydata.sort((a, b) => a.fan.year - b.fan.year));
    else if (newValue === 0) setmydata((mydata) => mydata.sort((a, b) => b.fan.year - a.fan.year));
    else if (newValue === 2) setmydata((mydata) => mydata.sort((a, b) => b.fan.click - a.fan.click));
    setKey(prevKey => 1 - prevKey);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: { xs: 320, sm: 600 },
        bgcolor: 'background.paper',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab label="按新到旧" />
        <Tab label="按旧到新" />
        <Tab label="点击量" />
        
      </Tabs>
    </Box>
  );
}

function ImgMediaCard({ fid, name, year, type, index, region, picname, state, click }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0, transition: { delay: index * 0.05 } }}
      whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
    >
      <a href={"/fans/" + fid} style={{ textDecoration: 'none' }}>
        <Card sx={{ maxWidth: 400, width: 400, height: 220, maxHeight: 220 }} >
          <Stack direction="row" alignItems={"flex-start"} justifyContent={"flex-start"} spacing={2}>
            <LazyLoadImage src={api_base + "/cover/download?name=" + picname} alt="封面图片" style={{ height: 200, margin: 10 }} />

            <Stack direction="column" alignItems={"flex-start"} justifyContent={"flex-start"} spacing={1}>
              <Typography gutterBottom variant="h7" component="div" style={{ marginTop: 10 }}>
                {name}
              </Typography>
              <Typography variant="body2" color="text.secondary" >
                年份：{year}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                地区：{region}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                类型：{type.map((item, index) => {
                  return numtotype(item) + " ";
                })}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                状态：{state}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                点击量：{click}
              </Typography>
            </Stack>

          </Stack>
        </Card>
      </a>
    </motion.div>
  );
}

export default function Home() {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [key, setKey] = React.useState(1);
  const [value, setValue] = React.useState(0);
  const [mydata, setmydata] = React.useState(data);
  const [selectyiwanjie, setselectyiwanjie] = React.useState(0);
  const [lianzai, setlianzai] = React.useState(0);
  const [select, setselect] = React.useState(
    Array(typenum()).fill(0)
  );
  const [displayflag, setdisplayflag] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setKey(prevKey => 1 - prevKey);
  };
  const [count, setcount] = React.useState(0);

  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  function handleClick(index) {
    const nextCounters = select.map((c, i) => (i === index ? 1 - c : c));
    setselect(nextCounters);

    setKey(prevKey => 1 - prevKey);
  }

  function handleSubmit() {

    if (searchTerm !== '') setmydata((mydata) => mydata.filter((item) => item.fan.fname.includes(searchTerm)));
    else setmydata(data);

    setKey(prevKey => 1 - prevKey);
  }

  let mycount = 0;
  let delaycount = 0;
  function wetherdisplay(item, index) {
    if (index === 0) mycount = 0;
    if (selectyiwanjie === 1 && item.fan.state !== "已完结") return "none";
    if (lianzai === 1 && item.fan.state === "已完结") return "none";
    for (let i = 0; i < select.length; i++) {
      if (select[i] === 1) {
        if (!item.types.includes(i)) return "none";

      }
    }
    mycount++;

    if (mycount > (page - 1) * 12 && mycount <= page * 12) {
      delaycount++;
      return "block";
    }
    else {
      return "none";
    }

  }

  function getcount() {
    let mycount = 0;
    for (let i = 0; i < mydata.length; i++) {
      if (selectyiwanjie === 1 && mydata[i].fan.state !== "已完结") continue;
      if (lianzai === 1 && mydata[i].fan.state === "已完结") continue;
      let flag = true;
      for (let j = 0; j < select.length; j++) {
        if (select[j] === 1) {
          if (!mydata[i].types.includes(j)) flag = false;
        }
      }
      if (flag)
        mycount++;
    }
    return parseInt((mycount - 1) / 12) + 1;
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFans();
        console.log(response.data.data);
        setData(response.data.data);
        setmydata(response.data.data);
        setmydata((mydata) => mydata.sort((a, b) => b.fan.year - a.fan.year));
        setdisplayflag(Array(response.data.data.length).fill("block"));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <Stack
          className="container"
          direction="column"
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
          style={{ marginTop: "10px" }}
        >
          <ScrollableTabsButtonVisible value={value} setValue={setValue} mydata={mydata} setmydata={setmydata} setKey={setKey} />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}

            style={{ marginTop: "10px" }}
          >
            {select.map((item, index) => {
              return (
                <motion.button
                  className="selectbutton"
                  onClick={() => handleClick(index)}
                  key={index}
                  style={{
                    background: select[index] === 1 ? "#01bae9" : "#a0f9ff",
                    color: select[index] === 1 ? "white" : "black",
                  }}
                  whileHover={{ scale: 1.05 }}
                >
                  {numtotype(index)}
                </motion.button>
              );
            })
            }
            <motion.button
              className="selectbutton"
              onClick={() => { setlianzai(1 - lianzai); setKey(prevKey => 1 - prevKey); }}
              style={{
                background: lianzai === 1 ? "#01bae9" : "#a0f9ff",
                color: lianzai === 1 ? "white" : "black",
              }}
              whileHover={{ scale: 1.05 }}
            >
              连载
            </motion.button>
            <motion.button
              className="selectbutton"
              onClick={() => { setselectyiwanjie(1 - selectyiwanjie); setKey(prevKey => 1 - prevKey); }}
              style={{
                background: selectyiwanjie === 1 ? "#01bae9" : "#a0f9ff",
                color: selectyiwanjie === 1 ? "white" : "black",
              }}
              whileHover={{ scale: 1.05 }}
            >
              已完结
            </motion.button>

            <SearchBox onSearchChange={handleSearchChange} />
            <motion.button
              className="confirmbutton"
              onClick={handleSubmit}
              whileHover={{ scale: 1.05 }}
            >
              确认
            </motion.button>
          </Stack>
          <CircularProgress />
        </Stack>

      </div>

    );
  }

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    );
  }





  return (
    <div>

      <Stack
        className="container"
        direction="column"
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={1}
        style={{ marginTop: "10px" }}
      >
        <ScrollableTabsButtonVisible value={value} setValue={setValue} mydata={mydata} setmydata={setmydata} setKey={setKey} />
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}

          style={{ marginTop: "10px" }}
        >
          {select.map((item, index) => {
            return (
              <motion.button
                className="selectbutton"
                onClick={() => handleClick(index)}
                key={index}
                style={{
                  background: select[index] === 1 ? "#01bae9" : "#a0f9ff",
                  color: select[index] === 1 ? "white" : "black",
                }}
                whileHover={{ scale: 1.05 }}
              >
                {numtotype(index)}
              </motion.button>
            );
          })
          }
          <motion.button
            className="selectbutton"
            onClick={() => { setlianzai(1 - lianzai); setKey(prevKey => 1 - prevKey); }}
            style={{
              background: lianzai === 1 ? "#01bae9" : "#a0f9ff",
              color: lianzai === 1 ? "white" : "black",
            }}
            whileHover={{ scale: 1.05 }}
          >
            连载
          </motion.button>
          <motion.button
            className="selectbutton"
            onClick={() => { setselectyiwanjie(1 - selectyiwanjie); setKey(prevKey => 1 - prevKey); }}
            style={{
              background: selectyiwanjie === 1 ? "#01bae9" : "#a0f9ff",
              color: selectyiwanjie === 1 ? "white" : "black",
            }}
            whileHover={{ scale: 1.05 }}
          >
            已完结
          </motion.button>

          <SearchBox onSearchChange={handleSearchChange} />
          <motion.button
            className="confirmbutton"
            onClick={handleSubmit}
            whileHover={{ scale: 1.05 }}
          >
            确认
          </motion.button>
        </Stack>
        <div className="container" key={key}>
          {mydata.map((item, index) => (
            <div style={{ display: wetherdisplay(item, index) }} key={index}>
              <ImgMediaCard
                fid={item.fan.fid}
                name={item.fan.fname}
                year={item.fan.year}
                region={item.fan.region}
                type={item.types}
                index={delaycount}
                picname={item.fan.picpath}
                state={item.fan.state}
                click={item.fan.click == null ? 0 : item.fan.click}
              />
            </div>
          ))}
        </div>
        <Pagination count={getcount()} variant="outlined" color="primary" page={page} onChange={handleChange} style={{ margin: 10 }} />
      </Stack>
    </div>
  );
}
