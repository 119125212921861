import React, { useState } from 'react';
import FileUpload from './FileUpload';
import { typenum,typetonum,numtotype } from './TypeandNum';
import { Input } from '@mui/material';
const Addfaninfo = ({formData,setFormData}) => {
 

    const typesOptions = [];
    for (let i = 0; i < typenum(); i++) {
        typesOptions.push(numtotype(i));
    }
    const daysOptions = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
    const stateOptions = ['连载', '已完结'];

    function nametoval(name)
    {
        switch (name) {
            case '周一':
                return 1;
            case '周二':
                return 2;
            case '周三':
                return 3;
            case '周四':
                return 4;
            case '周五':
                return 5;
            case '周六':
                return 6;
            case '周日':
                return 7;
        }
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => {
                if (checked) {
                    return { ...prevFormData, types: [...prevFormData.types, typetonum(value)] };
                } else {
                    return { ...prevFormData, types: prevFormData.types.filter((type) => type !== typetonum(value)) };
                }
            });
        }
        else if(name==='weekday')
        {
            setFormData((prevFormData) => ({
                ...prevFormData,
                "fan": {...prevFormData.fan,
                [name]: nametoval(value),
                }
            }));
        } 
        else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                "fan": {...prevFormData.fan,
                [name]: value,
                }
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#f7f7f7',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        maxWidth: '100%',
        margin: '0 auto'
    };
    const radioLabelStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px'
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc'
    };

    const labelStyle = {
        marginBottom: '5px',
        fontWeight: 'bold'
    };

    const buttonStyle = {
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#4CAF50',
        color: 'white',
        cursor: 'pointer',
        marginTop: '20px'
    };

    const checkboxContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%'
    };

    const checkboxLabelStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '48%'
    };

    const radioContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '10px',
        fontWeight:'normal',
        alignItems: 'center',
        marginBottom: '5px'
    };

  
    return (
        <div>
            <div style={{ width: '100%' }}>
              
              <label style={labelStyle}>
                  封面:
                  <FileUpload setFormData={setFormData} currentpic={formData.fan.picpath}/>
              </label>
          </div>
        <form  style={formStyle}>
            <div style={{ width: '100%' }}>
              
                <label style={labelStyle}>
                    番剧名字:
                    <input
                        type="text"
                        name="fname"
                        value={formData.fan.fname}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                </label>    
            </div>
            <div style={{ width: '100%' }}>
                <label style={labelStyle}>
                    更新时间:
                    <div style={radioContainerStyle}>
                    {daysOptions.map((day) => (
                    <label key={day} >
                        <input
                            type="radio"
                            name="weekday"
                            value={day}
                            checked={daysOptions[formData.fan.weekday-1] === day}
                            onChange={handleChange}
                        />
                        {day}
                    </label>
                ))}
                </div>
                </label>
            </div>
            <div style={{ width: '100%' }}>
                <label style={labelStyle}>
                    状态:
                    <div style={radioContainerStyle}>
                    {stateOptions.map((day) => (
                    <label key={day} >
                        <input
                            type="radio"
                            name="state"
                            value={day}
                            checked={formData.fan.state=== day}
                            onChange={handleChange}
                        />
                        {day}
                    </label>
                ))}
                </div>
                </label>
            </div>
            <div style={{ width: '100%' }}>
                <label style={labelStyle}>
                    地区:
                    <input
                        type="text"
                        name="region"
                        value={formData.fan.region}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                </label>
            </div>
            <div style={{ width: '100%' }}>
                <label style={labelStyle}>
                    年份:
                    <input
                        type="text"
                        name="year"
                        value={formData.fan.year}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                </label>
            </div>
            <div style={{ width: '100%', marginTop: '10px' }}>
                <label style={labelStyle}>类型:</label>
                <div style={checkboxContainerStyle}>
                    {typesOptions.map((type) => (
                        <label key={type} style={checkboxLabelStyle}>
                            <input
                                type="checkbox"
                                name="types"
                                value={type}
                                checked={formData.types.includes(typetonum(type))}
                                onChange={handleChange}
                            />
                            {type}
                        </label>
                    ))}
                </div>
            </div>
            
        </form>
        </div>
    );
};

export default Addfaninfo;
