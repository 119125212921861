import React, { useState, useEffect } from 'react';
import ProgressLable from './ProgressLable';


export default function ProgressShower() {
    

    return (
        <div>
            <h1>进度</h1>
            <ProgressLable/>
        </div>
    );
}
