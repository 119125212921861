import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addreview } from './Server';
import { useParams } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';
import { Stack } from '@mui/material';
export default function Reply({father,precontent,setfresh}) {
  const [open, setOpen] = React.useState(false);
  const {eid}=useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
        {localStorage.getItem('token')==null?<Button variant="outlined" onClick={handleClickOpen} disabled >
        回复
      </Button>:
      <IconButton variant="outlined" onClick={handleClickOpen} >
        <ReplyIcon color='success' />
      </IconButton>}
      
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.reply;
            addreview(father,eid,email,localStorage.getItem('token')).then((res) => {
              setfresh(pre=>1-pre);
            handleClose();
            });
          },
          style: { width: '600px', height: '400px' } // 设置宽度和高度
        }}
      >
        <DialogTitle>回复评论</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} alignItems={"flex-start"} justifyContent="space-between">
          <DialogContentText>
            {precontent}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="reply"
            label="回复"
            fullWidth
            multiline
            variant="standard"
          />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button type="submit">确定</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
