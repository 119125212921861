import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { deepOrange, deepPurple } from '@mui/material/colors';
import DelCommentButton from './DelCommentButton';
import Reply from './Reply';
import LikeButton from './LikeButton';
import ReplyIcon from '@mui/icons-material/Reply';
// 评论数据示例

function Subcomment({ rid, username, content, timestamp, setfresh }) {

    return (
        <div style={{ display: "flex", "marginTop": "5px", "marginBottom": "15px", "marginLeft": "15px", "marginRight": "15px" }}>
            <Card style={{ "textAlign": "left" }} variant="outlined" sx={{ maxWidth: 700, width: 700 }}>
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography color="text.secondary" gutterBottom variant="h6" component="div">
                            {username}
                        </Typography>
                        <Typography color="text.secondary" gutterBottom variant="h6" component="div" style={{ "marginLeft": "50px" }}>
                            {timestamp}

                        </Typography>

                    </Stack>

                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Typography color="text.secondary" variant="body2" style={{ "marginLeft": "10px", "marginRight": "10px" }}>
                            {content}
                        </Typography>

                    </Stack>

                </Box>

            </Card>
            <DelCommentButton setfresh={setfresh} rid={rid} />
        </div>
    );
}

export default function CommentShower({ likeNum, username, rid, comment, timestamp, replydata, setfresh }) {
    return (
        <div style={{ display: "flex", "marginTop": "15px" }}>

            <Avatar style={{ "marginRight": "15px" }} sx={{ bgcolor: deepOrange[500] }}>{username[0]}</Avatar>
            <Card style={{ "textAlign": "left" }} variant="outlined" sx={{ maxWidth: 720, width: 720 }}>
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="h5" component="div">
                            {username}:
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div" style={{ "marginLeft": "50px" }}>
                            {timestamp}
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Typography color="text.primary" variant="body2" style={{ "marginLeft": "10px", "marginRight": "10px" }}>
                            {comment}
                        </Typography>
                    </Stack>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" spacing={1}>
                        
                            <LikeButton likeNum={likeNum} rid={rid} setfresh={setfresh}/>
                        
                        <Reply precontent={comment} father={rid} setfresh={setfresh} />
                        <DelCommentButton rid={rid} setfresh={setfresh} />
                    </Stack>
                </Box>
                {replydata.map((item, index) => {
                    return <Subcomment setfresh={setfresh} rid={item.review.rid} username={item.ename} content={item.review.content} timestamp={item.review.rtime} key={index} />
                })}
            </Card>
        </div>
    );
}



