import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/Login'
import Home from './components/Home'
import FansShower from './components/FansShower'
import MenuBar from './components/MenuBar'
import EnhancedTable from './components/EnhancedTable'
import DramaPlayer from './components/DramaPlayer'
import Signup from './components/Signup'
import Week from './components/Week'
import MessageList from './components/MessageList'
import { lightGreen } from '@mui/material/colors'
import { getprofile } from './components/Server'
import Admin from './components/Admin'
import ProgressShower from './components/ProgressShower'
import Charge from './components/Charge'
import CommentManager from './components/CommentManager'
import HistoryShower from './components/HistoryShower'
import StarShower from './components/StarShower'

function App() {
  const [logindata, setLogindata] = React.useState({islogin: false, username: '',role:'normal'});
 

  React.useEffect(() => {
    if(localStorage.getItem('token')!==null){
      getprofile(localStorage.getItem('token')).then((res)=>{
        if(res.data.code===20000){
          setLogindata({
            islogin: true, 
            username: res.data.data.Username,
            id:res.data.data.id,
            role:res.data.data.role
          });
        }
        else
        {
          setLogindata({
            islogin: false, 
            username: '',
          
            role:'normal'
          });
          localStorage.removeItem('token');
        }
      })
    }
  }, []);
  

  
  return (
    <div>
      <MenuBar  logindata={logindata}/>
      <Router>
        <Routes>
          <Route exact path='/login' element={<Login setLogindata={setLogindata}/>} />
          <Route exact path='/signup' element={<Signup />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/fans/:fid' element={<FansShower />} />
          <Route exact path='/epic/:eid' element={<DramaPlayer />} />
          {logindata.role==="admin"?<Route exact path='/admin' element={<Admin logindata={logindata} />} />:<Route exact path='/admin' element={<Home />} />}
          <Route exact path='/progress' element={<ProgressShower />} />
          <Route exact path='/mycomment' element={<CommentManager />} />
          <Route exact path='/history' element={<HistoryShower />} />
          <Route exact path='/Message' element={<MessageList />} />
          <Route exact path='/star' element={<StarShower/>} />
          <Route exact path='/week' element={<Week />} />
          <Route exact path='/charge' element={<Charge />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

