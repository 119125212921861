import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { Stack } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export default function IconLabelTabs({value, handleChange}) {
  

  return (
    <Stack direction="row" spacing={2} justifyContent={"center"}>
    <Tabs value={value} onChange={()=>handleChange(1-value)} aria-label="icon label tabs example">

      <Tab icon={<SupervisorAccountIcon />} label="用户消息" />
      <Tab icon={<ChatIcon />} label="系统通知" />
    </Tabs>
    </Stack>
  );
}