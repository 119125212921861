import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useState } from 'react';
import { likereview } from './Server';

export default function LikeButton({ likeNum, rid, setfresh }) {


    return (
        <div>
            <Badge badgeContent={likeNum} color="primary">
                <IconButton onClick={() => {
                    likereview(rid, localStorage.getItem('token')).then((res) => {
                        setfresh(pre => 1 - pre);
                    }
                    )
                }}>

                    <ThumbUpIcon color='info' />

                </IconButton>
            </Badge>
        </div>

    );


}