import React, { useState } from 'react';
import './DramaPlayer.css';
import Epiclist from './EpicList';
import CommentShower from './CommentShower';
import MessageShower2 from './MessageShower2';
import { getepisode,addfavorite,getfavoriteall } from './Server';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { addreview } from './Server';
import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton } from '@mui/material';
import { getfanone,api_base } from './Server';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

const DramaPlayer = () => {
  const [episodes, setEpisodes] = useState( [
    { id: 1, title: 'Episode 1', qualities: {
      '360p': 'https://sample-videos.com/video123/mp4/360/big_buck_bunny_360p_1mb.mp4',
      '720p': 'https://v16m-default.akamaized.net/81b628b60815cdee32ae3198df1288c4/666b0e56/video/tos/alisg/tos-alisg-ve-0051c001-sg/owf32yYkrmTEIgERsejFvDRDBQG5FENWDwQARB/?a=2011&bti=MzhALjBg&ch=0&cr=0&dr=0&net=5&cd=0%7C0%7C0%7C0&br=816&bt=408&cs=0&ds=3&ft=XE5bCqq3mbuPD12aoOt73wU1NmxdEeF~O5&mime_type=video_mp4&qs=0&rc=N2k1OGU0M2Q4ZjQ6ZWg4ZEBpamx0dHI5cndlcjMzODYzNEBjXi8yXmAuNjQxNi1gLzQ0YSNwNGNfMmRzLl9gLS1kMC1zcw%3D%3D&vvpl=1&l=20240613085713784B14D9E01CB3E2201A&btag=e000a8000',
      '1080p': 'https://v16m-default.akamaized.net/81b628b60815cdee32ae3198df1288c4/666b0e56/video/tos/alisg/tos-alisg-ve-0051c001-sg/owf32yYkrmTEIgERsejFvDRDBQG5FENWDwQARB/?a=2011&bti=MzhALjBg&ch=0&cr=0&dr=0&net=5&cd=0%7C0%7C0%7C0&br=816&bt=408&cs=0&ds=3&ft=XE5bCqq3mbuPD12aoOt73wU1NmxdEeF~O5&mime_type=video_mp4&qs=0&rc=N2k1OGU0M2Q4ZjQ6ZWg4ZEBpamx0dHI5cndlcjMzODYzNEBjXi8yXmAuNjQxNi1gLzQ0YSNwNGNfMmRzLl9gLS1kMC1zcw%3D%3D&vvpl=1&l=20240613085713784B14D9E01CB3E2201A&btag=e000a8000'
    }}
  ]);
  const [currentEpisode, setCurrentEpisode] = useState(episodes[0]);
  const [myindex,setmyindex] = useState(0);
  const [currentQuality, setCurrentQuality] = useState('720p');
  const [loading, setLoading] = useState(false);
  const [fresh,setfresh]=useState(0);
  const [sendloading,setsendloading]=useState(false);
 

  const {eid}=useParams();

  const handleEpisodeClick = (index) => {
    setCurrentEpisode(episodes[index]);
    setmyindex(index);
    setLoading(true);
  };

  const handleQualityChange = (e) => {
    setCurrentQuality(e.target.value);
    setLoading(true);
  };

  const handleCanPlay = () => {
    setLoading(false);
  };

  function ReplyInput() {
    let content = '';
    
    
    function handleClick()
    {
      if(content==''||content==null)
        {
          alert("评论不能为空");
          return;
        }
      setsendloading(true);
      addreview(null,eid,content,localStorage.getItem('token')).then((res) => {
      
        setfresh(1-fresh);
        setsendloading(false);
      });
    }


    return (
      <Box 
        style={{ 
          textAlign: 'center',
          
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
        }} 
        variant="outlined"  
        sx={{ maxWidth: 720, width: 720 }}
      >
        <input 
          type="text" 
          placeholder="发布评论..." 
          onChange={(e) => { content = e.target.value; }}
          style={{
            width: "80%",
            padding: "8px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginRight: "8px"
          }} 
        />
         {sendloading ? <CircularProgress size={40}/>:<button
        onClick={handleClick}
          style={{
            padding: "8px 16px",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: localStorage.getItem('token')==null?"#e1e1ea":"#007BFF",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer"
          }}
          disabled={localStorage.getItem('token')==null}
        >
          发布
        </button>}
        
      </Box>
    );
  }

  const [mydata,setmydata]=useState({other:[],review:[]});
  const [reviewdata,setreviewdata]=useState([]);
  const [replydata,setreplydata]=useState(new Map());
  const [playurl,setplayurl]=useState('');
  const [title,settitle]=useState('加载中...');
  const [favorite,serfavorite]=useState([]);

  const [mybool,setmybool]=useState(false);
  React.useEffect(() => {
    setLoading(true);
     getepisode(eid,localStorage.getItem('token')).then((res) => {
      setmydata(res.data.data);
      setEpisodes(res.data.data.other);
      setplayurl(res.data.data.other.filter((item) => {return item.eid==eid;})[0].url);

      getfanone(res.data.data.other[0].fid).then((res) => {
        settitle(res.data.data.fan.fname);
      }
      );

      replydata.clear();
      res.data.data.review.forEach((item) => {
        if(item.review.father!=null&&item.review.admin1!=null&&item.review.admin2!=null)
        {
          if(replydata.has(item.review.father))
          {
            replydata.get(item.review.father).push(item);
          }
          else{
          replydata.set(item.review.father,[item,]);
          }
        }
      } );
      setreviewdata(res.data.data.review.filter((item) => {return item.review.father==null&&item.review.admin1!=null&&item.review.admin2!=null;}));

     
      serfavorite([]);
      if(localStorage.getItem('token')!=null)
        getfavoriteall(localStorage.getItem('token')).then((res) => {
          serfavorite(res.data.data.favourites.favourites);
          setmybool(false);
          res.data.data.favourites.favourites.map((item, index) => {
           
            if (item.favourite.eid == eid) {
              setmybool(true);
            }
          })
        }

        );

      setLoading(false);
    });
  }
  , [fresh,localStorage.getItem('token')]);

  return (
    <div className="drama-player">
      <h1>{title}</h1>
      
      <div className="video-container">
        <div>
         
          <video
            width="800"
            height="450"
            controls
            src={api_base+"/video/video?fileName="+playurl}
            onCanPlay={handleCanPlay}
          >
            
            Your browser does not support the video tag.
          </video>
          {mybool?<p>已收藏</p>:<IconButton onClick={()=>{
            addfavorite(eid,localStorage.getItem('token')).then((res) => {
              alert("收藏成功");
              setmybool(true);
            });
          }}><StarBorderOutlinedIcon color='info' style={{ fontSize: '30px' }}/></IconButton>}
          
          <div className="quality-select">
          </div>
        </div>
        <div className="episode-list">
          <h2>选择集数</h2>
          <Epiclist eid={eid} episodes={episodes} currentEpisode={myindex} handleEpisodeClick={handleEpisodeClick} />
        </div>
      </div>
     
      <ReplyInput />
      {loading ? <CircularProgress size={40}/>:<IconButton onClick={()=>{setfresh(1-fresh)}}><ReplayIcon/></IconButton>}
      {reviewdata.map((item,index) => {
        return (
          <CommentShower 
            username={item.ename}
            comment={item.review.content}
            timestamp={item.review.rtime}
            key={index}
            likeNum={item.review.likeNum}
            rid={item.review.rid}
            setfresh={setfresh}
            replydata={replydata.has(item.review.rid)?replydata.get(item.review.rid):[]}
          />
        );
      })}

      <div style={{ "textAlign":"left","width":"1200px" }}>
      
      </div>
     
    </div>
  );
};

export default DramaPlayer;

