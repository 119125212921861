// src/MenuBar.js
import React from 'react';
import './MenuBar.css';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import MessageShower from './Users';
import { Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import CottageIcon from '@mui/icons-material/Cottage';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LoginIcon from '@mui/icons-material/Login';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Button from '@mui/material/Button';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StarIcon from '@mui/icons-material/Star';
import UploadButtonMenu from './UploadButtonMenu';
import UploadButton from './UploadvideoButton';


//<li className="menu-item"><a href="/Message"><Badge badgeContent={3} color="primary"><MailIcon fontSize="large" /></Badge></a></li>
const MenuBar = ({ logindata }) => {
  return (
    <nav className="menu-bar">
      <ul className="menu">
        <div className="menu">
          <li className="menu-item"><a href="/"><CottageIcon fontSize="large" ></CottageIcon></a></li>
          <li className="menu-item"><a href="/admin"><BorderColorIcon fontSize="large" /></a></li>
          
          <li className="menu-item"><a href="/week"><DateRangeIcon fontSize="large"></DateRangeIcon></a></li>
          <li className="menu-item"><a href="/star"><StarIcon fontSize="large"></StarIcon></a></li>
          <li className="menu-item"><a href="/history"><ScheduleIcon fontSize="large"></ScheduleIcon></a></li>
          
        </div>
        <MessageShower logindata={logindata} />
      </ul>
    </nav>

  );
};

export default MenuBar;

