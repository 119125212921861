
export function typenum(){
    return 11;
}

export function typetonum(type) {
  switch (type) {
    case '校园':
        return 0;
    case '冒险':
        return 1;
    case '奇幻':
        return 2;
    case '悬疑':
        return 3;
    case '战斗':
        return 4;
    case '恋爱':
        return 5;
    case '搞笑':
        return 6;
    case '魔法':
        return 7;
    case '运动':
        return 8;
    case '恐怖':
        return 9;
        case '学习':
        return 10;
  }
}

export function numtotype(num) {
    switch (num) {
        case 0:
            return '校园';
        case 1:
            return '冒险';
        case 2:
            return '奇幻';
        case 3:
            return '悬疑';
        case 4:
            return '战斗';
        case 5:
            return '恋爱';
        case 6:
            return '搞笑';
        case 7:
            return '魔法';
        case 8:
            return '运动';
        case 9:
            return '恐怖';
            case 10:
                return '学习';
    }
    }