import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';



export default function EpicList({episodes,eid,currentEpisode,handleEpisodeClick}) {


    

    function renderRow(props) {
        const { index, style } = props;
      
        return (
          <ListItem style={style} key={index} component="div" disablePadding>
            <ListItemButton onClick={()=>{window.location.href = '/epic/'+episodes[index].eid;}} style={{background:eid==episodes[index].eid?"#66ff66":"#ffffff"}}>
              <ListItemText primary={episodes[index].ename} />
            </ListItemButton>
          </ListItem>
        );
      }

  return (
    <Box
      sx={{ width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}
    >
      <FixedSizeList
        height={400}
        width={200}
        itemSize={46}
        itemCount={episodes.length}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
}

