import React, { useState } from 'react';

const Chargeinfo = () => {
  const [amount, setAmount] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 在这里处理充值逻辑，例如调用API
    alert(`You have selected the ${selectedPlan} plan and will recharge ${amount} amount.`);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>升级到高级会员</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="plan">选择充值计划:</label>
          <select
            id="plan"
            value={selectedPlan}
            onChange={handlePlanChange}
            style={styles.select}
          >
            <option value="">选择</option>
            <option value="Monthly">月付 - $10</option>
            <option value="Yearly">年付- $100</option>
          </select>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="amount">输入银行卡号:</label>
          <input
            type="number"
            id="account"
            value={amount}
            onChange={handleAmountChange}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="amount">输入银行卡密码:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={handleAmountChange}
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>确认</button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
  },
  header: {
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  select: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Chargeinfo;
