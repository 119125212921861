import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { approve, reject } from './Server';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteIcon from '@mui/icons-material/Delete';

import CircularProgress from '@mui/material/CircularProgress';




export default function AccordionUsage({ reviews, logindata, visit, setvisit }) {


    const [loading, setLoading] = React.useState(false);
    const [loadnum, setLoadnum] = React.useState(0);
    const [apploading, setApploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    console.log(reviews);

    function removereview(index) {
        
      
        setvisit((previsit)=>previsit.map((item, idx) => {
            if (idx === index)
                return true;
            else
                return item;
        }));
    }
    let myindex = 0;

    let mycount = 0;
    function approveall() {
        setApploading(true);
        setProgress(0);
        mycount = 0;
        reviews.map((review,index) => (
            visit[mycount] || review.admin1 == logindata.id || review.admin2 == logindata.id ? null :
                approve(review.rid, localStorage.getItem('token')).then((res) => {
                    mycount++;
                    setProgress((pre)=>pre+1 / myindex * 100);
                    removereview(index);
                })
        ))
        setApploading(false);
    }

    return (
        <div>
            <Button variant="outlined" onClick={approveall} startIcon={<DoneAllIcon />}>
                全部批准
            </Button>
            {apploading ? <CircularProgress variant='determinate' value={progress}/> : null}
            {reviews.map((review, index) => (
                visit[index] || review.admin1 == logindata.id || review.admin2 == logindata.id ? null :
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            {myindex++}:
                            {review.content}
                            {visit[index] ? '已处理' : '未处理'}
                        </AccordionSummary>
                        <AccordionDetails>
                            发布人id： {review.uid}
                        </AccordionDetails>
                        <AccordionDetails>
                            内容： {review.content}
                        </AccordionDetails>
                        <AccordionDetails>
                            时间： {review.rtime}
                        </AccordionDetails>
                        <AccordionDetails>
                            <input type="text" name={"reason" + index} placeholder="违规理由" />
                        </AccordionDetails>
                        <AccordionActions>
                            {loadnum == index && loading ? <CircularProgress /> : null}
                            <Button onClick={() => {
                                setLoading(true);
                                setLoadnum(index);
                                approve(review.rid, localStorage.getItem('token')).then((res) => {
                                    setLoading(false);
                                    if (res.data.code === 20000) {
                                        removereview(index);

                                    }
                                    else {
                                        alert(res.data.message);
                                        removereview(index);
                                    }
                                }
                                )

                            }}>批准</Button>

                            <Button onClick={() => {

                                if (document.getElementsByName('reason' + index)[0].value === '') {
                                    alert('请填写违规理由');
                                    return;
                                }
                                setLoading(true);
                                setLoadnum(index);
                                reject(review.rid, localStorage.getItem('token'), document.getElementsByName('reason' + index)[0].value).then((res) => {
                                    setLoading(false);
                                    if (res.data.code === 20000) {
                                        removereview(index);
                                    }
                                    else {
                                        alert(res.data.message);
                                    }
                                })
                            }}>拒绝</Button>
                            <Button onClick={() => { window.location.href = "/epic/" + review.eid }}>详情</Button>
                        </AccordionActions>
                    </Accordion>

            ))}

        </div>
    );
}