import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import SelectSmall from './SelectSmall';
import { Input, Select, Stack } from '@mui/material';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { motion } from 'framer-motion'; // Import framer-motion
import axios from 'axios';
import Addfan from './Addfan';
import DelButton from './DelButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import * as Server from './Server';
import CustomizedProgressBars from './CustomizedProgressBars';
import { numtotype } from './TypeandNum';
import EditIcon from '@mui/icons-material/Edit';
import { Edit } from '@mui/icons-material';
import Editfan from './Editfan';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import EpisodeManager from './EpisodeManager';
import SuccessTip from './SuccessTip';


const styles = {
  scheduleContainer: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '1200px',
    margin: '20px auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }
};

const fanheadcells = [
  {
    id: 'fid',
    label: 'fid',
  },
  {
    id: 'fname',
    label: 'fname',
  },
  {
    id: 'year',
    label: 'year',
  },
  {
    id: 'click',
    label: 'click',
  },
  {
    id: 'weekday',
    label: 'weekday',
  },
  {
    id: 'state',
    label: 'state',
  },
  {
    id: 'type',
    label: 'type',
  }
];

const userheadcells = [
  {
    id: 'uid',
    label: 'uid',
  },
  {
    id: 'username',
    label: 'username',
  },
  {
    id: 'email',
    label: 'email',
  },
  {
    id: 'role',
    label: 'role',
  },
  {
    id: 'createdAt',
    label: 'createdAt',
  },
];

export default function EnhancedTable() {

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [headCells, setHeadCells] = React.useState(fanheadcells);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('fid');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectitem, setselectitem] = React.useState('Fans');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [mydata, setmydata] = React.useState(0);
  const [selectshowdata, setselectshowdata] = React.useState("Fans");
  const [successopen, setSuccessOpen] = React.useState(false);
  const [inputsearch, setinputsearch] = React.useState("");
  const [editformData, seteditformData] = React.useState({
    fan: {
      fname: '',
      weekday: '',
      region: '',
      year: '',
      state: ''
    },
    types: [],
  });


  function descendingComparator(a, b, orderBy) {
    if (selectitem === "Fans") {
      if (b.fan[orderBy] < a.fan[orderBy]) {
        return -1;
      }
      if (b.fan[orderBy] > a.fan[orderBy]) {
        return 1;
      }
    }
    else if (selectitem === "Users") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleReplay() {
    setLoading(true);
    setmydata(1 - mydata);
  }

  function handleEdit() {
    seteditformData(rows.filter((item) => { return item.id === selected[0] })[0]);
    seteditformData((prevFormData) => ({ ...prevFormData }));
    setOpen2(true);
  }

  function handleepisode() {
    seteditformData(rows.filter((item) => { return item.id === selected[0] })[0]);
    seteditformData((prevFormData) => ({ ...prevFormData, "fan": { ...prevFormData.fan, year: "" } }));
    setOpen3(true);
  }

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >


        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >

            <SelectSmall selectitem={selectitem} setselectitem={setselectitem} setOrderBy={setOrderBy} />
          </Typography>


        )}



        {numSelected === 0 && <div style={{ "display": "flex" }} >
          <IconButton onClick={handleReplay}>
            {loading ? <CustomizedProgressBars /> : <ReplayRoundedIcon />}
          </IconButton>
          <IconButton onClick={handleClickOpen}>
            {selectitem == "Fans" ? <AddCircleOutlineRoundedIcon /> : null}
          </IconButton>
        </div>}
        {numSelected === 1 && (
          <>
            <IconButton onClick={handleEdit}>
              {selectitem == "Fans" ? <EditIcon /> : null}
            </IconButton>
            <IconButton onClick={handleepisode}>
              {selectitem == "Fans" ? <PlaylistPlayIcon /> : null}
            </IconButton>
            <DelButton selected={selected} mydata={mydata} setmydata={setmydata} setselected={setSelected} selectitem={selectitem} />
          </>
        )}
        {numSelected > 1 && <DelButton selected={selected} mydata={mydata} setmydata={setmydata} setselected={setSelected} selectitem={selectitem} />}





      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              align={index == 0 ? 'left' : 'center'}
              padding={index == 0 ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).filter((item) => { return selectshowdata=='Fans'?item.fan.fname.includes(inputsearch):true }).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, order, orderBy, page, rowsPerPage, inputsearch],
  );


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (selectitem === "Fans") {
          const response = await Server.getFans();
          response.data.data.map((item, index) => {
            item.id = item.fan.fid;
          });
          console.log(response.data.data);
          setHeadCells(fanheadcells);
          setRows(response.data.data);
          setOrderBy('fid');
          setselectshowdata("Fans");
          // setSuccessOpen(true);
        }
        else if (selectitem === "Users") {

          const response = await Server.getusers(localStorage.getItem('token'));
          response.data.data.map((item, index) => {
            item.id = item.uid;
          });
          console.log(response.data.data);
          setHeadCells(userheadcells);
          setRows(response.data.data);
          setOrderBy('uid');
          setselectshowdata("Users");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectitem, mydata]);


  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    );
  }


  let content;
  if (selectshowdata === "Fans") {
    content = <TableBody>
      {visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (

          <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >

            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </TableCell>

            <TableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
            >
              {row.fan.fid}
            </TableCell>
            <TableCell align="center">{row.fan.fname}</TableCell>
            <TableCell align="center">{row.fan.year}</TableCell>
            <TableCell align="center">{row.fan.click}</TableCell>
            <TableCell align="center">{row.fan.weekday}</TableCell>
            <TableCell align="center">{row.fan.state}</TableCell>
            <TableCell align="center">{row.types.map((item) => { return numtotype(item) + " " })}</TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: (dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  }
  if (selectshowdata === "Users") {
    content = <TableBody>
      {visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (

          <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </TableCell>

            <TableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
            >
              {row.uid}
            </TableCell>
            <TableCell align="center">{row.username}</TableCell>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">{row.role}</TableCell>
            <TableCell align="center">{row.createdAt}</TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: (dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  }





  return (
    <motion.div key={rows} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>

          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              {content}
            </Table>
          </TableContainer>
          
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
          <Input value={inputsearch} onChange={(e)=>{setinputsearch(e.target.value)}}/>
      </Box>
      <Addfan open={open} setOpen={setOpen} />
      <Editfan open={open2} setOpen={setOpen2} formData={editformData} setFormData={seteditformData} setSelected={setSelected} />
      <EpisodeManager open={open3} setOpen={setOpen3} editformData={editformData} />
      <SuccessTip open={successopen} setOpen={setSuccessOpen} />

    </motion.div>
  );
}