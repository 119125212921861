import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSmall({selectitem, setselectitem,setOrderBy}) {
  

  const handleChange = (event) => {
    setselectitem(event.target.value);
    if(event.target.value==='Fans'){
      
    }
    else if(event.target.value==='Users'){
     // setOrderBy('uid');
    }
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
     
      <Select
        value={selectitem}
        onChange={handleChange}
      >
        <MenuItem value={'Fans'}>Fans</MenuItem>
        <MenuItem value={'Users'}>Users</MenuItem>
      </Select>
    </FormControl>
  );
}