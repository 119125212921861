import React, { useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { api_base } from './Server';

const FileUpload = ({ setFormData, currentpic }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [cropper, setCropper] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  React.useEffect(() => {
    if (currentpic !== "") {
      setPreviewUrl(api_base + "/cover/download?name=" + currentpic);
    }
  }, [currentpic]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        formData.append('file', blob);
        uploadFile(formData);
      });
    } else {
      formData.append('file', selectedFile);
      uploadFile(formData);
    }
  };

  const uploadFile = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post(api_base + '/cover/upload', formData);
      console.log(response.data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        fan: {
          ...prevFormData.fan,
          picpath: response.data.data.filename,
        },
      }));
      setLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleGetImage = async () => {
    setLoading(true);
    const response = await fetch(inputValue);
    const blob = await response.blob();
    const formData = new FormData();
    formData.append('file', blob, 'image.jpg');
    setPreviewUrl(URL.createObjectURL(blob));
setLoading(false);
    
  };

  const handleSetAspectRatio = () => {
    if (cropper) {
      cropper.setAspectRatio(256 / 356);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', maxWidth: '800px', margin: 'auto' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: '100%' }}>
        {loading && <CircularProgress />}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          <input
            name="picpath"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="输入图片url"
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '100%' }}
          />
          <button
            onClick={handleGetImage}
            style={{
              padding: '10px',
              borderRadius: '4px',
              border: 'none',
              background: '#00ccff',
              color: '#fff',
              cursor: 'pointer',
              fontWeight: 'bold'
            }}
          >
            从URL获取
          </button>
        </div>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
          <button
            type="submit"
            style={{ padding: '10px', borderRadius: '4px', border: 'none', background: '#00ccff', color: '#fff', cursor: 'pointer', fontWeight: 'bold' }}
          >
            上传
          </button>
          图片{currentpic}已保存至服务器
        </form>
        <button
          onClick={handleSetAspectRatio}
          style={{ padding: '10px', borderRadius: '4px', border: 'none', background: '#00ccff', color: '#fff', cursor: 'pointer', fontWeight: 'bold' }}
        >
          设置裁剪比例为 256x356
        </button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '300px', height: '300px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f0f0f0' }}>
        {previewUrl ? (
          <Cropper
            src={previewUrl}
            style={{ width: '100%', height: '100%' }}
            initialAspectRatio={NaN} // 不限制初始长宽比例
            guides={false}
            cropBoxResizable={true}
            cropBoxMovable={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        ) : (
          <p style={{ textAlign: 'center', color: '#aaa' }}>暂无图片</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
