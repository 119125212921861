import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import CommentStepShower from './CommentStepShower';
import { getallreview, delreview } from './Server';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function CommentManager() {

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [reviews, setReviews] = React.useState([]);


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getallreview(localStorage.getItem('token'));
        if (response.data.data.reviews != null)
          setReviews(response.data.data.reviews);

        console.log(reviews);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '20px auto',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}>
      {reviews.map((review,index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >

            <Stack sx={{ width: '100%' }} spacing={2} direction="row" justifyContent="space-between" ><Alert severity={review.admin1 != null && review.admin2 != null ? "success" : review.noMessage != null ? "error" : "info"}> {review.content}</Alert>{review.rtime}</Stack>

          </AccordionSummary>
          <AccordionDetails>
            <CommentStepShower step={review.admin1 == null && review.admin2 == null ? 1 : review.admin1 == null || review.admin2 == null ? 2 : 3} />
            {review.noMessage!=null?<Alert severity="error">{review.noMessage}</Alert>:<div></div>}
          </AccordionDetails>
          <AccordionActions>
            <Button onClick={async () => {
              await delreview(review.rid,localStorage.getItem('token'));
              window.location.reload();
            }}>撤回</Button>
            <Button onClick={()=>{
              window.location.href='/epic/'+review.eid;
            }}>查看评论</Button>
          </AccordionActions>
        </Accordion>
      ))
      }
    </div>
  );
}